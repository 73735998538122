/**
 * $PAGE
 */

.page {
    position: relative;
}

    .page__wrap {
        max-width: $max-page-width;
        margin: 0 auto;
        padding: 40px 20px;
    }

        .page__headline {
            font-size: 2rem;
            line-height: 1.25;
            border-bottom: 1px solid rgba($text-color, 0.5);
            padding-bottom: 0.5rem;
            margin: 0 0 2rem;
        }

        .page__children {
            margin-top: 2rem;
            border: 1px solid $sidebar-border-color;
            background: $sidebar-background-color;
            padding: 1rem;

            ol {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            li {
                margin-bottom: 0.5rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

            .page__child {
                color: inherit;
                text-decoration: none;

            }

        .page__edit {
            border-top: 1px solid rgba($text-color-muted, 0.5);
            color: $text-color-muted;
            transition: color 300ms ease;
            font-size: 0.8rem;
            margin: 2rem 0 0;
            padding: 2rem 0;
            line-height: 1.25;

            &:hover {
                color: $text-color;
            }
        }

            .page__updated {
                margin: 0;
                float: left;
            }

            .page__actions {
                float: right;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;
                    margin-right: 2rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                a {
                    color: inherit;
                    text-decoration: none;
                    font-weight: bold;

                }
            }
