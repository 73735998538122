/**
 * $FONT
 */


/**
 * #FONT FACES
 */
.serif {
    font-family: $font-family-serif;
}

.sans-serif {
    font-family: $font-family-sans-serif;
}


/**
 * #FONT SIZES
 */
.larger {
    font-size: 120%;
}

.large {
    font-size: 110%;
}

.normal {
    font-size: 100%;
}

.small {
    font-size: 90%;
}

.smaller {
    font-size: 80%;
}

/**
 * #COLOR VARIATIONS
 */

.white {
    background: $background-color-base;
    color: $text-color;
}

.sepia {
    background: $background-color-sepia;
    color: $text-color-sepia;
}
