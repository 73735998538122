/**
 * $PAGE
 */


html {
    font-size: 100%;
    line-height: $line-height-base;
    font-weight: $font-weight-base;
    background-color: $background-color-base;
    color: $text-color;
    height: 100%;
    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
     -webkit-font-smoothing: antialiased;


    &.serif {
        font-family: $font-family-serif;
    }

    &.sans-serif {
        font-family: $font-family-sans-serif;
    }
}

    body {
        height: 100%;
        overflow-x: hidden;
    }

        .wrap {
            position: relative;
            width: 100%;
            height: 100%;
        }
