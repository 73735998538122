/**
 * $BOOK
 */

.book {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

    .book__wrap {
        min-height: calc(100% - 60px);
        @media (min-width: $alt-book-layout-breakpoint) {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

        .book__header {
            font-family: $font-family-sans-serif;
            color: $text-color-muted;
            height: 50px;
            padding: 0 10px;
            font-size: 14px;
            overflow: visible;

            .sepia & {
                color: $text-color-sepia-muted;
            }

            .action--left:first-child {
                margin-left: 0;
            }

            .action--right {
                margin-right: 0;
            }

            &:hover .book__title {
                opacity: 1;
            }
        }

        .book__title {
            margin: 0;
            padding: 0 50px;
            font-weight: 400;
            font-size: 18px;
            line-height: 50px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            opacity: 0;
            transition: opacity 300ms ease;

            a {
                text-decoration: none;
                color: inherit;
            }
        }


/**
 * #NAVIGATION TOGGLE
 */
.with-sidebar .book {
    left: $sidebar-width;

    @media (max-width: ($sidebar-width * 2)) {
        transform: translate(calc(100% - 60px), 0);
        left: 0;
    }
}

.with-animation .book {
    transition: left 300ms ease, transform 300ms ease;

    @media (max-width: ($sidebar-width * 2)) {
        transition: transform 300ms ease;
    }
}


/**
 * #PREV / NEXT BUTTONS
 */
.book__previous,
.book__next {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    float: left;
    width: 50%;
    max-width: 50%;
    height: 60px;

    color: $text-color-muted;
    font-size: 30px;
    text-decoration: none;
    transition: color 300ms;

    @include attention() {
        color: $text-color;
    }

    .sepia & {
        color: $text-color-sepia-muted;

        @include attention() {
            color: $text-color-sepia;
        }
    }
}

.book__next {
    float: right;
}

@media (min-width: $alt-book-layout-breakpoint) {

    .book__previous,
    .book__next {
        position: absolute;
        top: 80px;
        bottom: 0px;
        width: 40px;
        height: auto;
        float: none;
    }

    .book__previous {
        left: 0;
    }

    .book__next {
        right: 0;
    }
}

/**
 * #PRINTABLE LAYOUT
 */

.printable {
    .book {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }

        .book__wrap {
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
        }

            .book__header {}

                .action--sidebar,
                .action--print {
                    display: none;
                }
}
