/**
 * $NAVIGATION
 */

.navigation {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -$sidebar-width;
    width: $sidebar-width;

    border-right: 1px solid $sidebar-border-color;
    background: $sidebar-background-color;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    line-height: $line-height-base;

    @media (max-width: ($sidebar-width * 2)) {
        width: calc(100% - 60px);
        left: -100%;
    }
}

    .navigation__wrap {
        overflow-y: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .navigation__divider {
        border: 0;
        height: 1px;
        background: $sidebar-border-color;
        margin: 10px 0;
    }

    .navigation__item {
        margin: 10px 15px;
    }

    .navigation__header {

        p {
            margin: 0;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }

        .navigation__logo {
            max-width: 100%;
            margin: 0 auto 1rem;
        }

    .navigation__footer {
        font-size: 80%;

        a {
            color: inherit;
            text-decoration: none;
            font-weight: bolder;
        }
    }

/**
 * #NAVIGATION TOGGLE
 */

.with-animation .navigation {
    transition: left 300ms ease;
}

.with-sidebar .navigation {
    left: 0;
}
