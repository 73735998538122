/**
 * $SUMMARY
 */

.summary {
    padding: 0;

    ol {
        padding-left: 1.2rem;
    }

    &,
    ol {
        list-style: none;
    }

    a {
        display: block;
        text-decoration: none;
        color: inherit;
        padding: 0.5rem 0;
    }


}
    .summary__item {}

    .summary__item--active > a {
        font-weight: 700;
    }
