/**
 * $FONT
 */


/**
 * #TEXT MANIPULATORS
 */
.smaller-text {
    font-size: 0.85em;
    line-height: inherit;
}

.larger-text {
    font-size: 1.15em;
    line-height: inherit;
}

.strong {
    font-weight: 700;
}
