.page__content {
    overflow: hidden;
    word-wrap: break-word;
    display: block;


    /**
     * #SPACING
     */
    p, blockquote,
    ul, ol, dl,
    table, pre, hr,
    figure {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    & > *:first-child {
        margin-top: 0 !important;
    }

    & > *:last-child {
        margin-bottom: 0 !important;
    }


    /**
     * #INLINE ELEMENTS
     */
    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    a {
        color: inherit;
        text-decoration: none;
        outline: 0;

        .white &:hover,
        .white &:focus,
        .white &:active {
            text-shadow: -1px -1px 0 $background-color-base,
                          1px -1px 0 $background-color-base,
                         -1px  1px 0 $background-color-base,
                          1px  1px 0 $background-color-base;
        }

        .sepia &:hover,
        .sepia &:focus,
        .sepia &:active {
            text-shadow: -1px -1px 0 $background-color-sepia,
                          1px -1px 0 $background-color-sepia,
                         -1px  1px 0 $background-color-sepia,
                          1px  1px 0 $background-color-sepia;
        }
    }

    figure,
    img {
        max-width: 100%;
        page-break-inside: avoid;
    }

    figure {
        margin: 0 2rem 2rem;
        text-align: center;

        &.no-margin {
            margin: 0 0 2rem;
        }
    }

        figure img {
            display: block;
        }


    /**
     * #HEADLINES
     */
    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.25;
    }

    h1 {
        font-size: 2rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        margin-bottom: 2rem;
    }

    h2 {
        font-size: 1.75rem;
        margin: 2rem 0;
    }

    h3 {
        font-size: 1.5rem;
        margin: 2rem 0 1.5rem;
    }

    h4 {
        font-size: 1.25rem;
        margin: 2rem 0 1rem;
    }

    h5, h6 {
        font-size: 1rem;
        margin: 1rem 0 1rem;
    }

    h6 {
        opacity: 0.75;
    }


    /**
     * #LISTS
     */
    ul {
        list-style: square outside;
        margin-left: 2em;
        padding: 0;

        ul {
            padding-left: 1rem;
        }
    }

    ol {
        list-style: decimal outside;
        margin-left: 2em;
        padding: 0;

        ol {
            padding-left: 1rem;
            list-style: lower-alpha outside;
        }
    }

    ol ol,
    ol ul,
    ul ul,
    ul ol {
        margin-bottom: 0;
    }

    dl {
        padding: 0;
    }

    dt {

        font-style: italic;
        font-weight: bold;
    }

    dd {
        margin-left: 2rem;
        margin-bottom: 0.5rem;
    }

    /**
     * #BLOCKQUOTES
     */
    blockquote {
        padding-left: 1rem;
        margin-left: 1rem;
        border-left: 4px solid rgba(0, 0, 0, 0.06);
        font-style: italic;

        & > *:first-child {
            margin-top: 0;
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }


    /**
     * #CODE
     */
    code,
    pre {
        font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
    }

    code {
        display: inline-block;
        padding: 0;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        margin: 0;
        font-size: 85%;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 3px;

        &:before,
        &:after {
            letter-spacing: -0.2em;
            content: "\00a0";
        }
    }

    pre {
        padding: 1rem;
        overflow: auto;
        font-size: 0.9rem;
        line-height: 1.5;
        background-color: rgba(0, 0, 0, 0.04);
        border: none;
        border-radius: 3px;
        word-wrap: normal;
    }

    pre > code {
        padding: 0;
        margin: 0;
        font-size: inherit;
        white-space: pre;
        background: none;
        border: 0;

        &:before,
        &:after {
            content: '';
        }
    }


    /**
     * #TABLES
     */
    table {
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        width: 100%;
        overflow: auto;
    }

    tr:nth-child(2n) {
        background-color: rgba(0, 0, 0, 0.04);
    }

    td,
    th {
        padding: 0.5rem 1rem;
        border: 1px solid #e7e7e7;

        .sepia & {
            border-color: #d1ccc0;
        }
    }

    th {
        font-weight: 700;
    }


    /**
     * #HR
     */
    hr {
        height: 4px;
        padding: 0;
        background-color: rgba(0, 0, 0, 0.06);
        border: 0 none;
        margin: 2rem 0;
    }

    /**
     * #FOOTNOTES
     */
    .footnotes {
        margin-top: 4rem;
        font-size: 0.8rem;

        p {
            margin: 0;
        }
    }
}
