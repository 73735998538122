/**
 * $DROPDOWN
 */

$dropdown-normal-background: darken($background-color-base, 2%);
$dropdown-sepia-background: darken($background-color-sepia, 2%);

.dropdown {
    position: absolute;
    top: calc(100% + 5px);
    left: calc(50% - 11px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .10);
    border-radius: 2px;
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: default;
    width: 9em;

    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms ease;
    z-index: 9999;

    .with-dropdown + & {
        visibility: visible;
        opacity: 1;
    }

    &:before {
        content: '';
        position: absolute;
        top: -6px;
        left: 4px;
        width: 0;
	    height: 0;
	    border-left: 6px solid transparent;
	    border-right: 6px solid transparent;
    }

    &:after {
        content: '';
        position: absolute;
        top: -5px;
        left: 5px;
        width: 0;
	    height: 0;
	    border-left: 5px solid transparent;
	    border-right: 5px solid transparent;
    }

    &.dropdown--left {
        left: auto;
        right: calc(50% - 11px);

        &:before {
            left: auto;
            right: 4px;
        }

        &:after {
            left: auto;
            right: 5px;
        }
    }
}

    .dropdown__item {
        &--half {
            width: 50%;
            float: left;
        }

        &--divider {
            clear: both;

            height: 0px;
            padding-top: 5px;
            margin-bottom: 5px;
        }
    }

        .dropdown__link {
            display: block;
            line-height: 2;
            padding: 0 15px;
            white-space: nowrap;
            transition: color 200ms ease;
            cursor: pointer;
            color: inherit;
            text-decoration: none;

            &:hover {
                color: $text-color;
            }
        }

/**
 * THEMING
 */

.white {
    .dropdown {
        background: $dropdown-normal-background;
        border: 1px solid darken($dropdown-normal-background, 10%);
        color: $text-color-muted;

        &:before {
            border-bottom: 6px solid darken($dropdown-normal-background, 7.5%);
        }

        &:after {
            border-bottom: 5px solid $dropdown-normal-background;
        }
    }

    .dropdown__item--divider {
        border-bottom: 1px solid darken($dropdown-normal-background, 5%);
    }
}

.sepia {
    .dropdown {
        background: $dropdown-sepia-background;
        border: 1px solid darken($dropdown-sepia-background, 10%);
        color: $text-color-sepia-muted;

        &:before {
            border-bottom: 6px solid darken($dropdown-sepia-background, 7.5%);
        }

        &:after {
            border-bottom: 5px solid $dropdown-sepia-background;
        }
    }

    .dropdown__item--divider {
        border-bottom: 1px solid darken($dropdown-sepia-background, 5%);
    }
}
