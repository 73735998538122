/**
 * $ERROR
 */

.error {
    display: table;
    width: 100%;
    height: 100%;

}

    .error__wrap {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

        .error__logo {
            max-width: 50%;
        }

        .error__headline {
            font-size: 1.5rem;
            margin: 1rem auto;
            max-width: 75%;
        }

        .error__content {
            margin: 0 auto;
            max-width: 50%;

            a {
                text-decoration: none;
            }
        }
