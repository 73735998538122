/**
 * $NPROGRESS
 */
#nprogress {
    pointer-events: none;

    .bar {
        background: $text-color;
        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
    }

    .peg {
        display: block;
        position: absolute;
        right: 0;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px $text-color, 0 0 5px $text-color;
        opacity: 1;
        transform: rotate(3deg) translate(0px, -4px);
    }
}
